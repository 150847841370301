import { updateDoc } from "firebase/firestore"
import { getDocument, useDocument } from "hooks/firestore/core/useDocument"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { getBasketRef } from "hooks/firestore/getRefs"
import { useShopId } from "hooks/localstate/context/useShopId"
import { useBasketId } from "hooks/localstate/localstorage/useBasketId"
import { useB2BStorefront } from "hooks/localstate/url/useB2BStorefront"
import { useTableIdFromUrl } from "hooks/localstate/url/useTableIdFromUrl"
import Basket from "types/firestore/basket"

// ts-prune-ignore-next
export function useBasket(): WithRef<Basket> | undefined
export function useBasket(basketId: string): WithRef<Basket> | undefined
export function useBasket(basketId: string, shopId: string): WithRef<Basket> | undefined
export function useBasket(basketId?: string, shopId?: string) {
  // TypeScript function overloads should prevent conditional calling of the hook
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const realShopId = shopId || useShopId()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const realBasketId = basketId || useBasketId()
  const tableIdFromUrl = useTableIdFromUrl()
  const { document } = useDocument(getBasketRef(realShopId, realBasketId))

  const b2b = useB2BStorefront()
  if (document && document.isB2B !== b2b) {
    updateDoc(document._ref, { isB2B: b2b || false } as Partial<Basket>)
  }

  if (document) {
    if (tableIdFromUrl) {
      if (tableIdFromUrl !== document.tableId || document.orderMode !== "selfOrder") {
        updateDoc(document._ref, {
          tableId: tableIdFromUrl,
          orderMode: "selfOrder",
        } as Partial<Basket>)
      }
    } else {
      if (document.orderMode === "selfOrder") {
        updateDoc(document._ref, {
          tableId: undefined,
          orderMode: "pickup",
          distributionOption: undefined,
        } as Partial<Basket>)
      }
    }
  }

  return document
}

// ts-prune-ignore-next
export async function getBasket(basketId: string, shopId: string) {
  return await getDocument(getBasketRef(shopId, basketId))
}
